var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "deal-container" }, [
    _c("div", { staticClass: "deal-header" }, [
      _c(
        "div",
        { staticClass: "deal-title", style: { color: _vm.fontColor } },
        [_c("span", [_vm._v(_vm._s(_vm._taskName))])]
      ),
      _c("div", { staticClass: "deal-tabs" }, [
        _c(
          "span",
          {
            class: { actived: _vm.currTabName == "form" },
            style: {
              color: _vm.currTabName == "form" ? _vm.fontColor : "#303133",
              borderBottomColor:
                _vm.currTabName == "form" ? _vm.borderColor : "",
            },
            on: {
              click: function ($event) {
                return _vm.changeTab("form")
              },
            },
          },
          [_vm._v(" " + _vm._s(_vm.$t("lang_process_form")) + " ")]
        ),
        _c(
          "span",
          {
            class: { actived: _vm.currTabName == "flow" },
            style: {
              color: _vm.currTabName == "flow" ? _vm.fontColor : "#303133",
              borderBottomColor:
                _vm.currTabName == "flow" ? _vm.borderColor : "",
            },
            on: {
              click: function ($event) {
                return _vm.changeTab("flow")
              },
            },
          },
          [_vm._v(" " + _vm._s(_vm.$t("lang_flow_chart")) + " ")]
        ),
      ]),
    ]),
    _c("div", { staticClass: "deal-main" }, [
      _c(
        "div",
        { staticClass: "deal-content" },
        [
          _c("form-viewer", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.currTabName == "form",
                expression: "currTabName == 'form'",
              },
            ],
            ref: "formViewerRef",
            staticClass: "deal-form",
            attrs: { btnObj: _vm.currentLayoutBtn },
            on: { submit: _vm.handleOperation },
          }),
          _c("flow-graph", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.currTabName == "flow",
                expression: "currTabName == 'flow'",
              },
            ],
            ref: "flowGrapgRef",
            staticClass: "deal-flow",
            attrs: { flowId: _vm.flowId, readonly: true },
          }),
          _c(
            "el-footer",
            {
              staticClass: "deal-btns",
              style: { borderColor: _vm.borderColor },
            },
            _vm._l(_vm.curOptBtns, function (item) {
              return _c(
                "el-button",
                {
                  key: item.actionKey,
                  staticClass: "deal-btn-item",
                  style: {
                    backgroundColor: _vm.fontColor,
                    borderColor: _vm.borderColor,
                  },
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handleOperation(item)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(item.displayActionName) + " ")]
              )
            }),
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "deal-logs" }, [
        _c(
          "h2",
          {
            staticStyle: { font: "normal bold 20px sans-serif" },
            style: { color: _vm.fontColor },
          },
          [_vm._v(_vm._s(_vm.$t("lang_client_flow_description")) + "：")]
        ),
        _c("span", {
          style: { color: _vm.fontColor },
          domProps: { innerHTML: _vm._s(_vm._description) },
        }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }