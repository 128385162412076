






































































import { Component, ProvideReactive, Vue, Watch } from 'vue-property-decorator';
import FormViewer from '@/components/form-design/viewer/form-viewer.vue';
import FlowGraph from '@/components/flow-design/flow-graph.vue';
import { NodeConfig } from '@logicflow/core';
import { GraphNodeData } from '@/models/flow/GraphNodeModel';
import { startup } from '@/api/flow-design/FlowApi';
import SystemUtil from 'global-ui/packages/utils/SystemUtil';

@Component({
  components: { FormViewer, FlowGraph }
})
export default class FlowStart extends Vue {
  operation: string = 'process_new';
  taskName: string = '';
  currTabName: string = 'form'; // 当前显示内容（form: 表单； flow: 流程图）
  currentGraphNode: NodeConfig = GraphNodeData;
  flowLoading: boolean = true;
  formLoading: boolean = true;
  nextStepBtns: any[] = [];

  currentOperationBtn: any = {}; // 当前点击的按钮(流程事件类型-提交、退回等)
  currentLayoutBtn: any = {}; // 当前点击的按钮（页面切换类型的-弹出框、切换页面）
  curOptBtns: any[] = []; // 当前要显示的
  stepBtnsStack: any[] = []; // 将每一步的按钮放入堆栈中，方便切换

  borderColor: string = 'transparent';
  fontColor: string = '';

  @ProvideReactive() flowId: any = null;
  @ProvideReactive() formId: any = null;
  @ProvideReactive() formDataId: any = null;

  get _flowConfig() {
    return this.$store.getters.flowConfig;
  }

  get _description() {
    if (this._flowConfig && this._flowConfig.description) {
      return this._flowConfig.displayDescription;
    }
    return '';
  }

  get _activedNode() {
    return this.$store.getters.activedNode;
  }

  get _taskName() {
    if (this._flowConfig && this._flowConfig.id && this._activedNode && this._activedNode.id) {
      return this.$t(this._flowConfig.name) + '-' + this._activedNode.text.displayValue;
    }
    return '';
  }

  get _formConfigs() {
    return this.$store.getters.formConfigs;
  }

  created() {
    this.flowId = this.$route.query.id;
  }

  /**
   * 初始化页面数据
   */
  initData() {
    this.curOptBtns = this.filterBtns(this._activedNode.properties.nodeActions);
    this.stepBtnsStack.push(this.curOptBtns);
  }

  initTheme() {
    let formConfig = this._formConfigs[this.formId];
    if (formConfig && formConfig.container.properties) {
      if (formConfig.container.properties.hasBorder) {
        this.borderColor = formConfig.container.properties.borderColor;
      }
      this.fontColor = formConfig.container.properties.fontColor;
    }
  }

  initBtns() {
    this.curOptBtns = this.filterBtns(this._activedNode.properties.nodeActions);
    this.stepBtnsStack.push(this.curOptBtns);
  }

  filterBtns(btns) {
    let result: any[] = [];
    btns.forEach(btnItem => {
      if (btnItem.taskPages.includes(this.operation)) {
        if (btnItem.children && btnItem.children.length) {
          btnItem.children = this.filterBtns(btnItem.children);
        }
        result.push(btnItem);
      }
    });
    return result;
  }

  /**
   * 构造所有按钮的map
   */
  buildOptBtnMap(btns) {
    let result = {};
    btns.forEach(btnItem => {
      result[btnItem.actionKey] = btnItem;
      if (btnItem.children && btnItem.children.length) {
        result = Object.assign(result, this.buildOptBtnMap(btnItem.children));
      }
    });
    return result;
  }

  // 切换显示内容
  changeTab(tabName) {
    this.currTabName = tabName;
    if (tabName == 'flow') {
      (this.$refs.flowGrapgRef as any).focusOnCenter();
    }
  }

  /**
   * 点击按钮时
   */
  handleOperation(btnItem) {
    if (btnItem.operation) {
      this.currentOperationBtn = { ...btnItem };
      switch (btnItem.operation) {
        case 'submit':
          this.startFlow();
          break;
        case 'save':
          this.saveFlow();
          break;
        default:
          break;
      }
    } else {
      this.currentLayoutBtn = { ...btnItem };
      this.curOptBtns = this.getOptBtns(btnItem);
    }
  }

  /**
   * 获取页面切换后的按钮
   */
  getOptBtns(btnItem) {
    if (btnItem.actionType == 'form_flush') {
      this.stepBtnsStack.push(btnItem.children);
    } else if (btnItem.actionType == 'form_back') {
      this.stepBtnsStack.pop();
    } else if (btnItem.actionType == 'form_dialog') {
    }
    if (this.stepBtnsStack && this.stepBtnsStack.length) {
      return this.stepBtnsStack[this.stepBtnsStack.length - 1];
    } else {
      return [];
    }
  }

  // 发起
  startFlow() {
    // 获取表单参数
    this.buildRequestParams().then((params: any) => {
      this.$confirm(this.$t('lang_launch_task') as string, this.$t('lang_tips') as string, {
        confirmButtonText: this.$t('lang_determine_') as string,
        cancelButtonText: this.$t('lang_cancel_') as string,
        type: 'warning'
      }).then(result => {
        startup(params).then((res: any) => {
          if (res.code == '1') {
            this.$message.success(res.message);
            this.closeWindow_old();
          } else {
            this.$message.error(res.message);
          }
        });
      });
    });
  }

  // 暂存
  saveFlow() {
    this.buildRequestParams(false).then((params: any) => {
      this.$confirm(this.$t('lang_draft') as string, this.$t('lang_tips') as string, {
        confirmButtonText: this.$t('lang_determine_') as string,
        cancelButtonText: this.$t('lang_cancel_') as string,
        type: 'warning'
      }).then((reqult: any) => {
        params.startWait = true;
        startup(params).then((res: any) => {
          if (res.code == '1') {
            this.$message.success(res.message);
            this.closeWindow_old();
          } else {
            this.$message.error(res.message);
          }
        });
      });
    });
  }

  /**
   * 构建请求参数
   */
  buildRequestParams(validate: boolean = true) {
    return new Promise((resolve, reject) => {
      let params: any = {
        processId: this.flowId,
        arguments: this.buildRequestParamsByBtn(this.currentOperationBtn)
      };
      let newGraphData = (this.$refs.flowGrapgRef as any).getGraphData();
      let oldGraphData = SystemUtil.cloneDeep(this._flowConfig.customBpmModel.graph);
      if (!this.isEqual(newGraphData, oldGraphData)) {
        let runtimeCustomModel = { config: this._flowConfig.customBpmModel.config, graph: newGraphData };
        params.runtimeCustomModel = runtimeCustomModel;
      }
      if (this.formId) {
        (this.$refs.formViewerRef as any)
          .getFormDataForSave(validate)
          .then(res => {
            params.formDataInstance = {
              formId: this.formId,
              formModelDataList: res.formModelDataList
            };
            resolve(params);
          })
          .catch(e => {
            reject(e);
          });
      } else {
        resolve(params);
      }
    });
  }

  // 构建按钮参数
  buildRequestParamsByBtn(btnItem) {
    let result = {};
    if (btnItem.paramValueSettings) {
      btnItem.paramValueSettings.forEach(item => {
        result[item.paramOptionName] = item.value;
      });
    }
    return result;
  }

  /**
   * 路由跳转回首页
   */
  closeWindow() {
    this.$router.push({ path: '/home' });
  }

  /**
   * 关闭窗口
   */
  closeWindow_old() {
    if (navigator.userAgent.indexOf('MSIE') > 0) {
      // IE
      if (navigator.userAgent.indexOf('MSIE 6.0') > 0) {
        // IE6
        window.opener = null;
        window.close();
      } else {
        // IE6+
        window.open('', '_top');
        window.top!.close();
      }
    } else if (navigator.userAgent.indexOf('Firefox') > 0 || navigator.userAgent.indexOf('Presto') > 0) {
      // FF和Opera
      window.location.href = 'about:blank';
      window.close(); // 火狐默认状态非window.open的页面window.close是无效的
    } else {
      window.opener = null;
      window.open('', '_self', '');
      window.close();
    }
  }

  //判断两个对象是否相等
  isEqual(objA, objB) {
    //相等
    if (objA === objB) return objA !== 0 || 1 / objA === 1 / objB;
    //空判断
    if (objA == null || objB == null) return objA === objB;
    //类型判断
    if (Object.prototype.toString.call(objA) !== Object.prototype.toString.call(objB)) return false;

    switch (Object.prototype.toString.call(objA)) {
      case '[object RegExp]':
      case '[object String]':
        //字符串转换比较
        return '' + objA === '' + objB;
      case '[object Number]':
        //数字转换比较,判断是否为NaN
        if (+objA !== +objA) {
          return +objB !== +objB;
        }

        return +objA === 0 ? 1 / +objA === 1 / objB : +objA === +objB;
      case '[object Date]':
      case '[object Boolean]':
        return +objA === +objB;
      case '[object Array]':
        //判断数组
        for (let i = 0; i < objA.length; i++) {
          if (!this.isEqual(objA[i], objB[i])) return false;
        }
        return true;
      case '[object Object]':
        //判断对象
        let keys = Object.keys(objA);
        for (let i = 0; i < keys.length; i++) {
          if (!this.isEqual(objA[keys[i]], objB[keys[i]])) return false;
        }

        keys = Object.keys(objB);
        for (let i = 0; i < keys.length; i++) {
          if (!this.isEqual(objA[keys[i]], objB[keys[i]])) return false;
        }
        return true;
      default:
        return false;
    }
  }

  @Watch('_formConfigs')
  formConfigsWatcher() {
    if (this._flowConfig && this._flowConfig.id && this._flowConfig.customBpmModel.config) {
      this.formId = this._flowConfig.customBpmModel.config.formId;
      this.initTheme();
    } else {
      this.formId = null;
    }
  }

  @Watch('_activedNode')
  activedNodeWatcher() {
    this.initBtns();
  }
}
